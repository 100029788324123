<template>
    <div>
        <page-header :title="title" :items="items" />

        <div class="card">
            <div class="card-body">
                <h5> Hello {{ user.firstName }}! </h5>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    page: {
        title: 'Dashboard'
    },

    data() {
        return {
            title: 'Dashboard',
            items: [
                {
                    text: 'Container Tracking',
                    to: '/'
                },
                {
                    text: 'Dashboard',
                    active: true
                }
            ]
        };
    },

    computed: {
        ...mapGetters({
            user: 'auth/loggedUser'
        })
    }
};
</script>
